import startset1 from "$/images/egift/anygift_set_01.png";
import startset2 from "$/images/egift/anygift_set_02.png";
import startset3 from "$/images/egift/anygift_set_03.png";
import startset4 from "$/images/egift/anygift_set_04.png";
import howtoReceive1 from "$/images/egift/HowtoReceive_01.png";
import howtoReceive2 from "$/images/egift/HowtoReceive_02.png";
import howtoReceive3 from "$/images/egift/HowtoReceive_03.png";
import howtoSend1 from "$/images/egift/HowtoSend_01.png";
import howtoSend2 from "$/images/egift/HowtoSend_02.png";
import howtoSend3 from "$/images/egift/HowtoSend_03.png";

export const egiftSections = {
  About: {
    id: "EgiftAbout",
    title: "BASE FOOD\neギフト",
  },
  Campaign: {
    id: "Valentine2025",
    title: "Valentine2025",
  },
  Send: {
    id: "EgiftSend",
    title: "eギフトの贈り方",
  },
  Receive: {
    id: "EgiftReceive",
    title: "eギフトの受け取り方",
  },
  Lineup: {
    id: "EgiftLineup",
    title: "贈れるギフト",
  },
  Faq: {
    id: "EgiftFaq",
    title: "よくある質問",
  },
};

export const egiftSectionItems = [
  egiftSections.About,
  egiftSections.Send,
  egiftSections.Receive,
  egiftSections.Lineup,
  egiftSections.Faq,
];

const egiftLineup = {
  set1: {
    name: "ブレッドセット",
    imageSrc: startset1,
    price: "¥4,274",
    key: "specialset/1",
    deliveryfee: true,
    singleLine: true,
  },
  set2: {
    name: "ブレッド&クッキーセット",
    imageSrc: startset2,
    price: "¥5,254",
    key: "specialset/2",
    deliveryfee: true,
    singleLine: false,
  },
  set3: {
    name: "ブレッド&パンケーキミックスセット",
    imageSrc: startset3,
    price: "¥5,692",
    key: "specialset/3",
    deliveryfee: true,
    singleLine: false,
  },
  set4: {
    name: "自分で選ぶ",
    imageSrc: startset4,
    price: "¥3,100~",
    key: "specialset/4",
    deliveryfee: true,
    singleLine: true,
  },
};

export const egiftLineupItems = [
  egiftLineup.set1,
  egiftLineup.set2,
  egiftLineup.set3,
  egiftLineup.set4,
];

const egiftHowToSend = {
  step1: {
    id: 1,
    title: "贈りたいギフトを\n選択する",
    content: "贈りたいセットを選びます。自由に商品を組み合わせることも可能です。",
    imageSrc: howtoSend1,
  },
  step2: {
    id: 2,
    title: "メッセージを入力して\n購入する",
    content:
      "必要項目を入力し、eギフトを購入します。デジタルカードやメッセージのカスタマイズもできます。",
    imageSrc: howtoSend2,
  },
  step3: {
    id: 3,
    title: "URLをSNSやメールで\n送信する",
    content:
      "注文完了するとeギフトの受け取りURLが発行されます。LINEなど各SNSやメールでURLをお伝えください。",
    imageSrc: howtoSend3,
  },
};

export const egiftHowToSendItems = [
  egiftHowToSend.step1,
  egiftHowToSend.step2,
  egiftHowToSend.step3,
];

const egiftHowToReceive = {
  step1: {
    id: 1,
    title: "eギフトURLが届く",
    content: "受け取りURL開いて、お届け先情報の入力に進みます。",
    imageSrc: howtoReceive1,
  },
  step2: {
    id: 2,
    title: "お届け先を入力する",
    content: "お届け先情報を入力します。入力した情報は、贈り主にはわからない仕組みです。",
    imageSrc: howtoReceive2,
  },
  step3: {
    id: 3,
    title: "ギフトを受け取る",
    content: "入力したお届け先に、\neギフト商品が配送されます。",
    imageSrc: howtoReceive3,
  },
};

export const egiftHowToReceiveItems = [
  egiftHowToReceive.step1,
  egiftHowToReceive.step2,
  egiftHowToReceive.step3,
];
