import clsx from "clsx";
import { StaticImageData } from "next/image";
import Link from "next/link";

import { Image } from "@/components/displays";
import { FreeShippingLabel } from "@/components/domains/product";
import { FEATURE_FLAG } from "@/configs/system";
import { formatCurrency } from "@/utils/currency";
import { nl2br } from "@/utils/jsx";

import styles from "./ProductItem.module.scss";

export interface ProductItemProps {
  product: {
    name: string;
    url: string;
    imageSrc: string | StaticImageData;
    subImageSrc?: string | StaticImageData;
    title: string;
    subtitle?: string;
    price: number;
    isSetProduct: boolean;
    description: React.ReactNode;
    isNew?: boolean;
    isFreeze?: boolean;
    isLimited?: boolean;
    meal: string;
    isConvenienceStoreOnly?: boolean;
    isPriceFixed?: boolean;
    hiddenOnLp?: boolean;
    hiddenOnProductPage?: boolean;
  };
  white?: boolean;
  className?: string;
}

export function ProductItem({ product, white, className }: ProductItemProps): React.ReactNode {
  return (
    <div className={clsx("col-12", "col-m-3", "mg__bottom__m", className)}>
      <Link className={clsx(styles.container, white && styles.whiteBackground)} href={product.url}>
        <div className={styles.main}>
          {product.isNew && <span className={styles.newBadge}>NEW</span>}
          <div className="clear__sp">
            <Image
              src={product.imageSrc}
              alt={product.name}
              width={150}
              height={150}
              className={clsx(styles.image, product.subImageSrc && styles.mainImage)}
            />
            {product.subImageSrc && (
              <Image
                src={product.subImageSrc}
                alt={product.name}
                width={150}
                height={150}
                loading="eager" // マウスオーバーでサブ画像を表示する。マウスオーバー時のちらつき防止のため先に画像を読み込む
                className={clsx(styles.image, styles.subImage)}
              />
            )}
          </div>
          <div className={clsx("clear__pc", styles.imageContainer)}>
            <Image
              src={product.imageSrc}
              alt={product.name}
              className={clsx(styles.image)}
              width={110}
              height={110}
            />
          </div>
          <div className={styles.detail}>
            {product.subtitle && (
              <p className={clsx("text__s", "text__bold")}>{product.subtitle}</p>
            )}
            <p className={clsx("text__l", "text__bold", "mg__bottom__s")}>{nl2br(product.title)}</p>
            <span className={clsx("text__m", "text__bold", "wsnr")}>
              {formatCurrency(product.price)}
              {!product.isSetProduct && !product.isPriceFixed && "〜"}
            </span>
            <wbr />
            <span className={clsx("text__s", "text__normal", "wsnr")}>
              {FEATURE_FLAG.isFreeShippingCampaignActive &&
                product.isSetProduct &&
                (product.isFreeze ? "（送料・税込）" : "（税込）")}
              {!FEATURE_FLAG.isFreeShippingCampaignActive &&
                product.isSetProduct &&
                "（送料・税込）"}
              {!product.isSetProduct &&
                `/ ${product.meal}${product.isConvenienceStoreOnly ? "" : "※2"}`}
            </span>
            <p className={clsx("text__s", "mg__top__s", styles.description)}>
              {product.description}
            </p>
            {product.isConvenienceStoreOnly && (
              <span className={clsx(styles.tag, "bg__yellow")}>コンビニ限定</span>
            )}
            {product.isFreeze && (
              <span className={clsx(styles.tag, "bg__darkblue text__white")}>冷凍</span>
            )}
            {product.isLimited && (
              <span className={clsx(styles.tag, "bg__red text__white")}>期間限定</span>
            )}
            {!product.isFreeze &&
              product.isSetProduct &&
              FEATURE_FLAG.isFreeShippingCampaignActive && (
                <FreeShippingLabel className="mg__top__m" />
              )}
          </div>
        </div>
      </Link>
    </div>
  );
}
